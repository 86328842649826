import React from 'react'
import { divide } from 'lodash'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import './blogs.css'
import ArticlePreview from '../templates/artical-preview.js'

class Blogs extends React.Component { 
  constructor(props){
    super(props);
  }
  componentDidMount() {
      
  }
  render() {
      const posts = get(this, 'props.data.allContentfulBlogPost.edges')

      return (
          <div className="wrapper">
              <ul id="article-list" className="article-list">
                  {posts.map(({ node }) => {
                      return (
                      <li key={node.slug}>
                          <ArticlePreview article={node} />
                      </li>
                      )
                  })}
              </ul>
        </div>
      )
  }
}

export default Blogs

export const pageQuery = graphql`
  query BlogsQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "YYYY-MM-DD")
          tags
          heroImage {
            fluid(maxWidth: 1000) {
              sizes
              src
              srcSet
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
